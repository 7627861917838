<template>
  <vx-card title="Survey Catalog">
    <div class="vx-input-group flex">
      <vs-button
        v-if="hasPermission('create')"
        class="mt-2 mr-2"
        color="success"
        type="border"
        icon-pack="feather"
        icon="icon-plus"
        @click="handleCreate()"
        >Create</vs-button
      >
    </div>
    <div class="vx-row">
      <div class="vx-col sm:w-1/1 w-full mb-base">
        <vs-tabs v-model="activeTab">
          <vs-tab label="Active">
            <div class="tab-text">
              <data-table :baseUrl="this.baseUrl" status="Active"></data-table>
            </div>
          </vs-tab>
          <vs-tab label="Inactive">
            <div class="tab-text">
              <data-table
                :baseUrl="this.baseUrl"
                status="Inactive"
              ></data-table>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </div>
  </vx-card>
</template>
<script>
import DataTable from "./DataTable.vue";
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    DataTable,
    Datepicker,
  },
  data() {
    return {
      baseUrl: "/api/sfa/v1/survey-catalog",
      activeTab: 0,
    };
  },
  methods: {
    handleCreate() {
      this.$router.push({
        name: "survey-catalog-create",
        // params: { id: id },
      });
    },
  },
  mounted() {
    this.$store.dispatch("user/getPermissions", "survey-catalog");
  },
  watch: {},
};
</script>
